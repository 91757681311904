

































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import util from '../../lib/util';

@Component({
    components: {  },
})
export default class ListMenu extends Vue {
    name: string = 'listMenu';
    @Prop({ type: String }) title: string;
    @Prop({ type: String }) entity: string;
    @Prop({ type: String }) main: string;
    @Prop({ type: String, default: '' }) submain: string;
    @Prop({ type: Boolean, default : false }) saState: boolean;
    drawer: boolean = false;
    selectedId: number = this.model.id;

    get list() {
        return this.$store.state[this.entity].list;
    }

    get loading() {
        return this.$store.state[this.entity].loading;
    }

    get model() {
        return this.$store.state[this.entity].editModel;
    }

    getSubMain(data: any) {
        let value = util.getEntityValue(data, this.submain);
        return value;
    }

    getColor(item: any) {
        let color = 'indigo';
        if (item.current_state_id != undefined) {
            if (this.saState && item.current_state != null)
                color = item.current_state.color;
            else {
                let data = util.getTypeValueById(item.current_state_id);
                if (data != null)
                    color = data.extra;
            }
        }
        return color;
    }

    handleChange(item: any) {
        this.$store.commit(this.entity + '/edit', item);
        this.$emit('on-change');
    }
}
