
























import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import EditOrder from './edit/edit-form.vue';
import CreateOrder from './create-form.vue';
import ViewOrder from './view-form.vue';
import Sentinel from './sentinel.vue';

class PageApplicationRequest extends PageRequest {
    customer: number = null;
    state: number = null;
    vertical: number = 0;
    nro: string = '';
    date_from: string = Util.abp.clock.today().substr(0, 8) + '01';
    date_to: string = Util.abp.clock.today();
}

@Component({
    components: { ListView, FormBase, CreateOrder, EditOrder, ViewOrder, Sentinel }
})
export default class Applications extends ListBase {
    entity: string = 'application';
    pagerequest: PageApplicationRequest = new PageApplicationRequest();
    filterForm: boolean = true;
    viewModalShow: boolean = false;
    sentModalShow: boolean = false;
    sState: any = Util.abp.list.getCodeList('E_APPLY');

    filterItems = [
        this.gItem({ type: 'radio', prop: 'vertical', label: 'Vertical', size: 3, showAll:true, hideDetails: true, generalType: 'T_VERTICAL', row:true, onchange: this.getpage }),
        this.gItem({ type: 'object', prop: 'customer', label: 'Buscar Cliente por Nombre, RUC, DNI, etc.', itemText: 'description', autocomplete: true, size: 3, returnObject: false, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Fecha Desde', size: 2, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 1, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'text', prop: 'nro', label: 'Nro', size: 1, hideDetails: true, onchange: this.getpage }),
        this.gItem({ type: 'object', prop: 'state', label: 'Estado', generalType: 'E_APPLY', size: 2, returnObject: false, hideDetails: true, onchange: this.getpage }),
    ]

    get columns() {
        let cols = [
            this.gColO({ type: 'chip', value: 'code', text: 'Nro', width: '120px', main: true }),
            this.gColO({ type: 'text', value: 'user.full_name', text: 'Comercial', sortable: false }),
            this.gColO({ type: 'text', value: 'vertical_id', text: 'Vertical', generalType: true, sortable: false }),
            this.gColO({ type: 'text', value: 'emsa_company', text: 'Empresa EMSA', sortable: false }),                
            this.gColO({ type: 'text', value: 'autonomy_name', text: 'Autonomia', sortable: false }),
            this.gColO({ type: 'text', value: 'customer.fullname', text: 'Cliente', sortable: false }),
            this.gColO({ type: 'text', value: 'total', text: 'Total Inversion', width: '80px', sortable: false }),
            this.gColO({ type: 'date', value: 'issue_date', text: 'Fecha de Solicitud' })
        ];

        if (this.isComite) {
            cols.push(this.gColO({ type: 'text', value: 'voted_text', text: 'Voto', sortable: false }));
        }

        cols.push(...[
            this.gColO({ type: 'chip', value: 'state_id', text: 'Estado', color: 'extra', state: true, generalType: true }),
            this.defaultActions('view|edit|delete', 160, [
                { name: 'verify', title: 'Verificar', icon: 'reviews', event: this.verify },
                { name: 'vote', title: 'Votar', icon: 'thumbs_up_down', event: this.vote },
                //{ name: 'reject', title: 'Rechazar', icon: 'block', event: this.confirm },
                //{ name: 'divider' },
                //{ name: 'delete', title: 'Anular', icon: 'block', event: this.cancelOrder },
            ])
        ]);

        return cols;
    }

    isVoted(item) {
        if (item.user_votes == null) return false;
        if (item.user_votes.length == 0) return false;
        return item.user_votes.indexOf(Util.abp.session.userId) >= 0;
    }

    get exluddeIds() {
        let idView = [];
        let idEdit = [];
        let idConf = [];
        let idDel = [];
        let idVote = [];
        this.$store.state.application.list.forEach(item => {                
            if (!(item.state_id == this.sState.NU && this.isRisk))
                idConf.push(item.id);
            if ((this.isCobranza || this.isRiskManager || this.isComite || item.reviewed || item.finished) && !Util.abp.session.isAdmin)
                idEdit.push(item.id);
            if (!(this.isComite && item.valid && item.reviewed && !item.observed && this.isComite && ((item.autonomy_process == 'C' && this.isComite && !item.voted) || (item.autonomy_process != 'C' && (this.isRisk || this.isRiskManager) && !(item.user_votes.length > 0))) && !item.voted))
                idVote.push(item.id);
            if (item.valid)
                idDel.push(item.id);
        });

        //if (btn == 'approve' && this.application.valid && this.application.reviewed && !this.application.observed && ((this.isAutonomyComite && this.isComite && !this.isVoted()) || (!this.isAutonomyComite && (this.isRisk || this.isRiskManager) && !this.isVoteCount())) && this.tab == 'request')


        return { view: idView, edit: idEdit, verify: idConf, delete: idDel, vote: idVote };
    }

    get isRisk() {
        return Util.abp.session.isRisk();
    }

    get isCobranza() {
        return Util.abp.session.isCobranza();
    }

    get isRiskManager() {
        return Util.abp.session.isRiskManager();
    }

    get isComite() {
        return Util.abp.session.isComite();
    }


    showSentinel() {
        this.sentModalShow = !this.sentModalShow;
    }
    view() {
        this.viewModalShow = !this.viewModalShow;
    }
    edit() {
        this.editModalShow = !this.editModalShow;
    }
    create() {
        this.createModalShow = !this.createModalShow;
    } 

    vote(item) {
        this.$store.commit('application/view', item);
        this.viewModalShow = !this.viewModalShow;
    }

    async verify(item) {
        this.$swal({
            title: 'Marcar como verificada la Solicitud?',
            text: 'Verifico correctamente el checklist de la solicitud y todos los datos',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Verificar!',
            cancelButtonText: 'No!',
            confirmButtonColor: '#4CAF50',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true
        }).then(async (result) => {
            if (result.value) {
                await this.$store.dispatch({
                    type: this.entity + '/verify',
                    data: { id: item.id }
                });

                this.getpage();
                this.$swal('Verificado', 'La Solicitud de Evaluacion ha sido verificada!', 'success')

            }
        });
    }


    async cancelOrder(item) {
        this.$swal({
            title: 'Anular Orden de Trabajo?',
            text: 'Al anular la Orden de Trabajo quedara inabilitada del sistema',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Anular!',
            cancelButtonText: 'No!',
            confirmButtonColor: '#FF5252',
            showCloseButton: true
        }).then(async (result) => {
            if (result.value) {
                await this.$store.dispatch({
                    type: this.entity + '/delete',
                    data: item
                });

                this.$swal('Anulado', 'La Orden de Trabajo ha sido anulada!', 'success');
                this.getpage();
            }
        });
    }

    onSuccessCreate() {
        this.edit();
        this.getpage();
    }
      

    async getpage() {
        let list: any = this.$refs.listView
        await list.getpage();
    }
    
    async mounted() {
        if (this.$route.query.create) {
            this.create();
            Util.toRoute(this, 'application');
        } else if (this.$route.query.id) {
            let model = await this.$store.dispatch({
                type: this.entity + '/get',
                id: this.$route.query.id
            });

            this.$store.commit(this.entity + '/edit', model);
            if (model.request_review)
                this.view();
            else
                this.edit();
            Util.toRoute(this, 'application');
        }
    }

    created() {
        this.$store.dispatch({ type: 'currency/getAllActive' });
        this.$store.dispatch({ type: 'user/getAllActive' });
    }

}