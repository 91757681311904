


























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Activos from '@/views/financial/financialstatement/activos.vue';
import Pasivos from '@/views/financial/financialstatement/activos.vue';
import Ganancias from '@/views/financial/financialstatement/ganancias.vue';
import Indicadores from '@/views/financial/financialstatement/indicadores.vue';


@Component({
    components: { Activos, Pasivos, Ganancias, Indicadores },
})

export default class FinancialStatementForm extends ListBase {
    tabModel: any = null;
    year: number = parseInt(Util.abp.clock.today().substr(0, 4));
    
    /***** computed methods *****/
    get loading() {
        return this.$store.state.financialstatement.loading;
    }

    get periods() {
        return this.gCommonList('years');
    }

    get typeActivo() {
        return Util.abp.list.get('T_STATEMENT','A').id;
    }

    get typePasivo() {
        return Util.abp.list.get('T_STATEMENT', 'P').id;
    }

    get application() {
        return this.$store.state.application.editModel;
    }
   
    /***** fields events *****/
    async loadData() {
        await this.$store.dispatch({
            type: 'financialstatement/getAll',
            data: {
                year: this.year,
                customer: this.application.customer_id
            }
        });
    }


    async mounted() {
        await this.loadData();
    }

    async created() {
    }
}
