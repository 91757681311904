


























































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Util from '@/lib/util'
import ListBase from '@/lib/listbase';
import Application from '@/store/entities/financial/application';
import FinancialRating from '@/store/entities/financial/financialrating';

@Component({
    components: {
        FormBase, ModalView, VWidget, VuePerfectScrollbar
    },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    },
})

export default class SentinelForm extends ListBase {
    entity: string = 'financialrating';
    @Prop({ type: Boolean, default: false }) value: boolean;
    application: Application = new Application();
    rating: FinancialRating = new FinancialRating();
    period: any = null;
    scrollSettings: any = {
        maxScrollbarLength: 160
    };
    modalSetting: any = {
        entity: "financialrating",
        titleFull: 'Central de Riegos', 
        showForm: false,
        persistent: false,
        showFootActions: false,
        width: '1200px',
    };

    /************* ARRAY ATTRIBUTES ***************/
    
    
    /************* COMPUTED METODOS ***************/
    get list() {
        return this.$store.state.financialrating.details.filter(item => { return item.fecha_pro == this.period });
    }

    get periods() {
        return this.$store.state.financialrating.periods;
    }

    get loading() {
        return this.$store.state.financialrating.loading;
    }
    
    get currency_sign() {
        return Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');            
    }
    
    
    /************* METODOS DE INICIALIZACION ***************/        
    initForm() {
        this.application = Util.extend(true, {}, this.$store.state.application.editModel);                        
        this.getSentinel();
    }

    /************* METODOS DE CALCULO ***************/

    /************* BOTONES DE ACCION ***************/       

    async getSentinel() {
        await this.$store.dispatch({
            type: 'financialrating/getRating',
            data: { customer: this.application.customer_id }
        });
        this.rating = Util.extend(true, {}, this.$store.state.financialrating.editModel);
    }

    async processApi() {
        this.$swal({
            title: 'Cargar datos de Central de Riesgos?',
            text: 'Esta seguro de volver a cargar la informacion',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Continuar!',
            cancelButtonText: 'No!',
            confirmButtonColor: '#F44336',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true
        }).then(async (result) => {
            if (result.value) {
                await this.$store.dispatch({
                    type: 'financialrating/processApi',
                    data: { customer: this.application.customer_id }
                });
                await this.getSentinel();
            }
        });
    }

    cancel() {
        this.$emit('input', false);
    }


    async mounted() {

    }

    async created() {
    }
}
