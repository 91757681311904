
































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import VWidget from '@/components/VWidget.vue';
import ApplicationDocument from '@/store/entities/financial/applicationdocument';
import Util from '@/lib/util'
import ListBase from '@/lib/listbase';

@Component({
    components: { ModalView, VWidget },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        } 
    },
})

export default class ApplicationDocumentForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    entity: string = "applicationdocument";
    document: ApplicationDocument = new ApplicationDocument();
    events: Array<any> = [];
    nonce: number = 0;
    modalSetting: any = {
        entity: "applicationdocument",
        titleFull: 'Agregar Documento Adicional',
        icon: 'folder',
        loadingProp: 'itemLoading',
        showForm: false,
        width: '600px'
    };

    onFileChange() {
        let ipt = (this.$refs.fileInput as any);
        if (ipt.files == undefined) return;
        if (ipt.files.length > 0) {
            if (!ipt.files.length)
                return;
            let file = ipt.files[0];

            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                let fileData = (e as any).target.result;
                vm.document.fileData = fileData;
                vm.document.filename = file.name;
                vm.document.filetype = file.type;
            };
            reader.readAsDataURL(file);
        }
    }

    openFile() {
        (this.$refs.fileInput as any).click();
    }

    clearFile() {
        this.document.filename = null;
        this.document.fileData = null;
    }

    async save() {
        if (!this.disabled) {

            this.document.application_id = this.application.id;

            await this.$store.dispatch({
                type: 'applicationdocument/upload',
                data: this.document
            });
            this.document.name = null;
            this.document.filename = null;
            this.document.fileData = null;

            this.$emit('save-success');
            this.$emit('input', false);
        }
    }

    /************* COMPUTED LIST ***************/
    get loading() {
        return this.$store.state.applicationdocument.loading;
    }

    get application() {
        return this.$store.state.application.editModel;
    }

    get disabled() {
        return this.document.name == null;
    }

    /********* EVENTOS DE RETORNO DE MODALES ********/

    async initForm() {
        this.document = Util.extend(true, {}, this.$store.state.applicationdocument.editModel);
    }


    cancel() {
        this.$emit('input', false);
    }

    async mounted() {
    }
}
