













































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util'
import ListBase from '@/lib/listbase';
import Application from '@/store/entities/financial/application';
import CustomerForm from '@/views/catalogs/customer/customer-form.vue';

@Component({
    components: { FormBase, ModalView, VWidget, CustomerForm },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            (this as any).detailsShow = val;
            if (!val) this.$emit('input', false);
        }
    },
})

export default class ApplicationForm extends ListBase {
    name: string = 'create-application';
    entity: string = 'application';
    @Prop({ type: Boolean, default: false }) value: boolean;
    application: Application = new Application();
    cusModalShow: boolean = false;
    tVert: any = Util.abp.list.getCodeList('T_VERTICAL');
    modalSetting: any = {
        entity: "application",
        titleFull: 'Nueva Solicitud',
        showForm: false,
        width: '1100px'
    };

    //sub_total: number; //FINANCIERA

    /************* ARRAY ATTRIBUTES ***************/
    itemsInfo = [             
        this.gItem({ type: 'radio', prop: 'autonomy_process', label: 'Proceso de Autonomia', size: 12, options: 'autonomys', row: true }),
        this.gItem({ type: 'radio', prop: 'vertical_id', label: 'Vertical', size: 6, chips: true, onchange: this.changeVertical, generalType: 'T_VERTICAL', row: true }),
        this.gItem({ type: 'select', prop: 'user_id', label: 'Ejecutivo Responsable', required: true, disabled: true, options: this.users, size: 3, itemText: 'full_name' }),
        this.gItem({ type: 'date', prop: 'issue_date', label: 'Fecha de Solicitud', size: 3 }),
        this.gItem({ type: 'object', prop: 'customer_id', label: 'Cliente', size: 6, exclShortkey: true, required: true, autocomplete: true, itemText: 'description', outerIcon: 'add_circle', appendEvent: this.new_customer }),
        this.gItem({ type: 'object', prop: 'representative_id', label: 'Representante Legal', entity: 'customer', visible: false, required: true, size: 6, exclShortkey: true, autocomplete: true, itemText: 'description', outerIcon: 'add_circle', appendEvent: this.new_customer }),
        this.gItem({ type: 'object', prop: 'guarantee_id', label: 'Aval', entity: 'customer', size: 6, visible: false, exclShortkey: true, autocomplete: true, itemText: 'description', outerIcon: 'add_circle', appendEvent: this.new_customer }),
        this.gItem({ type: 'object', prop: 'currency_id', label: 'Moneda', entity: 'currency', size: 6, required: true, hint: '', persistentHint: true, onchange: this.changeCurrency }),
        this.gItem({ type: 'text', prop: 'emsa_company', label: 'Empresa EMSA', size: 12, maxLen: 255, hideDetails: true }),            
    ];

    itemsF = [
        this.gItem({ type: 'object', prop: 'customer_type_id', label: 'Tipo de Cliente', generalType: 'T_CUSTOMER', size: 4 }),
        this.gItem({ type: 'object', prop: 'annual_sale_id', label: 'Ventas Anuales', generalType: 'ANNUAL_SALE', size: 4 }),
        this.gItem({ type: 'object', prop: 'segment_id', label: 'Segmento', generalType: 'SEGMENT', size: 4 }),
        this.gItem({ type: 'price', prop: 'total_income', label: 'Renta Total', hint: 'Sin IGV', persistentHint: true, size: 4, prefix: this.application.currency_sign }),
        this.gItem({ type: 'price', prop: 'total', label: 'Total Inversion', hint: 'Sin IGV', persistentHint: true, size: 4, prefix: this.application.currency_sign }),
        this.gItem({ type: 'text', prop: 'autonomy', label: 'Autonomia', size: 4 }),
        this.gItem({ type: 'switch', prop: 'special_perm', label: 'Requiere Permisos Especiales', size: 4 }),
        this.gItem({ type: 'text', prop: 'specification', label: 'Especificar', size: 8, maxLen: 255 }),
        this.gItem({ type: 'textarea', prop: 'description', label: 'Descripcion de Empresa', size: 12, maxLen: 1000 }),
        this.gItem({ type: 'textarea', prop: 'warranty', label: 'Condiciones y/o Garantias', size: 12, maxLen: 1000 }),
        this.gItem({ type: 'object', prop: 'evaluator_id', label: 'Evaluador', entity: 'user', itemText: 'full_name', size: 6 }),
        this.gItem({ type: 'spacer', prop: 'spacer', size: 6 }),
    ];
     
    itemsT = [
        //this.gItem({ type: 'text', prop: 'good', label: 'Bien a Financiar', size: 12 }),
        //this.gItem({ type: 'text', prop: 'manufacturer', label: 'Marca', size: 6 }),
        //this.gItem({ type: 'price', prop: 'total', label: 'Monto De Mutuo', size: 3, prefix: this.application.currency_sign }),
        //this.gItem({ type: 'price', prop: 'prepaid', label: 'Aporte del Cliente', size: 3, prefix: this.application.currency_sign }),
        //this.gItem({ type: 'number', prop: 'months', label: 'Plazo', size: 3, suffix: 'Meses' }),
        //this.gItem({ type: 'percent', prop: 'tea', label: 'TEA', size: 3  }),
        //this.gItem({ type: 'number', prop: 'days_stock', label: 'Dias en Stock', size: 3, suffix: 'Dias' }),
        //this.gItem({ type: 'percent', prop: 'profit', label: 'Margen', size: 3 }),
        this.gItem({ type: 'textarea', prop: 'warranty', label: 'Garantia', size: 9, maxLen: 1000 }),
        this.gItem({ type: 'number', prop: 'assets', label: 'Activos de la Empresa', size: 3, prefix: '#' }),
        this.gItem({ type: 'textarea', prop: 'description', label: 'Descripcion de Empresa', size: 12, maxLen: 1000 }),
        this.gItem({ type: 'textarea', prop: 'notes', label: 'Proposito', size: 12, maxLen: 1000 }),
    ];

    get columnsT() { 
        let sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
        if (this.application != null) {
            let data = Util.getEntity(this, 'currency', this.application.currency_id);
            if (data != undefined) {
                sign = data.sign;
            }
        }

        return [
            { value: 'number', text: '#', sortable: false },
            { value: 'good', text: 'Bien a Financiar', sortable: false, class: 'px-1' },
            { value: 'quantity', text: 'Cantidad', sortable: false, class: 'px-1' },
            { value: 'manufacturer', text: 'Marca', sortable: false, class: 'px-1' },
            { value: 'total_amount', text: 'Monto Total', sortable: false, class: 'px-1' },
            { value: 'total', text: 'Monto Mutuo (' + sign + ')', sortable: false, class: 'px-1' },
            { value: 'prepaid', text: 'Aporte Cliente (' + sign + ')', sortable: false, class: 'px-1' },
            { value: 'months', text: 'Plazo (Meses)', sortable: false, class: 'px-1' },
            { value: 'tea', text: 'TEA (%)', sortable: false, class: 'px-1' },
            { value: 'days_stock', text: 'Dias Stock', sortable: false, class: 'px-1' },
            { value: 'profit', text: 'Margen (%)', sortable: false, class: 'px-1' }
        ];
    }
    
    new_customer() { //evento para abrir formulario de nuevo proveedor
        this.$store.commit('customer/new');
        this.cusModalShow = true;
    };
    
    changeVertical(id) {
        let data = Util.getTypeValueById(id);
        if (data == undefined) return;

        this.setItem(this.itemsInfo, 'representative_id', 'visible', (data.code == 'T'));
        this.setItem(this.itemsInfo, 'guarantee_id', 'visible', (data.code == 'T'));
        this.setItem(this.itemsInfo, 'spacer', 'visible', (data.code == 'F'));

    }

    changeCurrency(id) {
        let data = Util.getEntity(this,'currency',id);
        if (data == undefined) return;
        this.application.currency_sign = data.sign;
        this.application.exchange_rate = data.conversion_rate;
        this.setItem(this.itemsInfo, 'currency_id', 'hint', (data.conversion_rate != 1 ? 'TC: ' + data.conversion_rate : ''));

        if (this.application.vertical_id == this.tVert.T) {
            this.setItem(this.itemsT, 'total', 'prefix', data.sign);
            this.setItem(this.itemsT, 'prepaid', 'prefix', data.sign);
        } else if (this.application.vertical_id == this.tVert.F) {
            this.setItem(this.itemsF, 'total', 'prefix', data.sign);
            this.setItem(this.itemsF, 'total_income', 'prefix', data.sign);
        }
    }

    addGood(item) {
        if (item.good != null && item.good != '') {
            this.$store.commit('application/addGood');
        }
    }

    deleteGood(index) {
        this.$store.commit('application/deleteGood', index);
    }

    /************* COMPUTED METODOS ***************/
    get isLeader() {
        return Util.abp.session.isLeader();
    }

    get loading() {
        return this.$store.state[this.entity].loading;
    }

    get goods() {
        return this.$store.state.application.goods;
    }

    get users() {
        return this.$store.state.user.activeList.filter(a => {
            if (this.isLeader) {
                return (a.leaders != null && a.leaders.indexOf(Util.abp.session.userId) >= 0) || a.id == Util.abp.session.userId;
            } else {
                return true;
            }
        });
    }

    /************* METODOS DE INICIALIZACION ***************/
    initForm() {
        this.application = Util.extend(true, {}, this.$store.state.application.editModel);            
        this.getLastnumber();           
        console.log(this.isLeader, this.users)

        this.setItem(this.itemsInfo, 'user_id', 'disabled', !this.isLeader && !Util.abp.session.isAdmin);
        this.setItem(this.itemsInfo, 'user_id', 'options', this.users);
    }

    /************* METODOS DE CALCULO ***************/
    async getLastnumber() {
        let number = await this.$store.dispatch({ type: 'application/getLastNumber' });
        this.application.code = number;
    }

    afterSaveProject(item) {
        //this.application.project = item;
        //this.changeProject(item);
    }

    /************* BOTONES DE ACCION ***************/
    async save() {
        if ((this.$refs.infoForm as any).validate()) {
            let model = Util.extend(true, {}, this.application);

            model.goods = this.goods;

            let newModel = await this.$store.dispatch({
                type: this.entity + '/create',
                data: model
            });

            this.$emit('save-success');

            this.$store.commit('application/edit', newModel);
            Util.abp.notify.success(this, 'La Solicitud ha sido registrada correctamente!');

            this.cancel();
        }
    }

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    mounted() {
        this.$store.dispatch({ type: 'manufacturer/getAllActive' });
    }

    created() {
    }
}
