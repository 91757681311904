



























































































































































































































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util'
import ListBase from '@/lib/listbase';
import Application from '@/store/entities/financial/application';
import History from '@/views/_shared/history.vue';
import Documents from '@/views/financial/application/edit/documents.vue';
import RiskReport from '@/views/financial/riskreport/form.vue';
import ApplicationDocument from '@/store/entities/financial/applicationdocument';
import Statements from './statement.vue';
import Votes from './votes.vue';
import Comments from './comments.vue';
import Chat from '@/components/chat.vue';

 
@Component({
    components: {
        FormBase, ModalView, VWidget, History, Documents, Statements, Chat, RiskReport, Votes, Comments
    },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    },
})

export default class ApplicationForm extends ListBase {
    entity: string = 'application';
    @Prop({ type: Boolean, default: false }) value: boolean;
    document: ApplicationDocument = new ApplicationDocument();
    application: Application = new Application();
    hisModalShow: boolean = false;
    stateModalShow: boolean = false;
    voteModalShow: boolean = false;
    comModalShow: boolean = false;
    selectedIndex: any = null;
    menu1: boolean = false;
    menu2: boolean = false;
    menu3: boolean = false;
    tab: any = 'request';
    comment: any = '';
    pState: any = Util.abp.list.getCodeList('E_APPLY');
    tVert: any = Util.abp.list.getCodeList('T_VERTICAL');
    modalSetting: any = {
        entity: "application",
        titleFull: 'Nueva Solicitud de Evaluacion',
        showForm: false,
        width: '75%',
        showFloatFootActions: true,
        persistent: false,
        showSaveButton: false
    };

    /************* ARRAY ATTRIBUTES ***************/
    
    
    /************* COMPUTED METODOS ***************/
    get loading() {
        return this.$store.state[this.entity].loading;
    }

    get showActions() {
        let value = (this.showButton('verify') || this.showButton('sentinel') || this.showButton('agreed') || this.showButton('requestReview') ||
            this.showButton('review') || this.showButton('observe') || this.showButton('close') || this.showButton('reopen'));
        return value;
    }

    get verticalName() {
        if (this.application != undefined && this.application.vertical_id != null) {
            return Util.abp.list.getItem(this.application.vertical_id).name;
        } else 
            return '';
    }

    get current_state() {
        if (this.application == undefined) return {};
        return Util.abp.list.getItem(this.application.state_id);
    }

    get currency_sign() {
        return Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');            
    }

    get isCommercial() {
        return Util.abp.session.isCommercial();
    }

    get isLeader() {
        return Util.abp.session.isLeader();
    }

    get isCobranza() {
        return Util.abp.session.isCobranza();
    }

    get behavior() {
        let typeDo = Util.abp.list.get('T_DOCUMENT', 'TD13');
        return this.$store.state.applicationdocument.list.filter(item => { return item.document_type_id == typeDo.id })[0];
    }

    get loadingDoc() {
        return this.$store.state.applicationdocument.itemLoading;
    }
    
    get isRisk() {
        return Util.abp.session.isRisk();
    }

    get isRiskManager() {
        return Util.abp.session.isRiskManager();
    }

    get isComite() {
        return Util.abp.session.isComite();
    }

    get isAutonomyComite() {
        return this.application.autonomy_process == 'C';
    }

    get showChat() {
        return this.application.valid && this.application.observed && (this.isRiskManager || this.isRisk || this.isLeader || (this.isCommercial && this.$store.state.conversation.totalCount > 0));
    }

    get enabledRequestReview() {
        return this.$store.state.riskreport.editModel.id > 0;
    }

    get expiredDays() {
        const observed_date = (this as any).$moment(this.application.observed_date);
        const hoy = (this as any).$moment();
        const days = hoy.diff(observed_date, 'days');
        return days >= Util.abp.setting.get('EXPIRATION_DAYS');
    }

    /************* METODOS DE INICIALIZACION ***************/
    getItem(id) {
        if (id == null) return {};
        let item = Util.abp.list.getItem(id);
        if (item == undefined) return {};
        return item;
    }
    
    async initForm() {
        this.application = Util.extend(true, {}, this.$store.state.application.editModel);
        let form = (this as any).$refs.form;
        this.tab = 'request';
        form.mainSetting.showFloatFootActions = this.showActions;
        form.mainSetting.titleFull = 'Solicitud de Evaluacion #' + this.application.code;

        let data = Util.getEntity(this, 'currency', this.application.currency_id);
        if (data != undefined) {
            this.application.currency_sign = data.sign;
            this.application.exchange_rate = data.conversion_rate;
        }

        this.$store.commit('applicationdocument/new');
        this.document = Util.extend(true, {}, this.$store.state.applicationdocument.editModel);

        if (this.$refs.docs != undefined)
            (this.$refs.docs as any).getpage();

        if (this.$refs.state != undefined)
            (this.$refs.state as any).loadData();

        this.$store.commit('conversation/setEntity', {
            entity: 'application',
            entity_id: this.application.id,
            //filter_id: filter_id,
        });

        await this.$store.dispatch({
            type: 'conversation/getAll',
            data: {}
        });

        let filter_id = 1;
        if (this.application.observed && this.application.request_review)
            filter_id = 2;
        else if (this.application.observed && this.application.reviewed)
            filter_id = 3;

        this.$store.commit('conversation/setMessages', filter_id);

        await this.loadRisk();
    }

    clearFile() {
        this.behavior.filename = null;
        this.behavior.fileData = null;
    }
    
    getIcon(filetype) {
        if (filetype == 'pdf')
            return 'mdi-file-pdf-box'
        else if (filetype == 'xls' || filetype == 'xlsx')
            return 'mdi-microsoft-excel'
        else if (filetype == 'doc' || filetype == 'docx')
            return 'mdi-microsoft-word'
        else if (filetype == 'png' || filetype == 'jpg' || filetype == 'jpeg')
            return 'mdi-image'
        else if (filetype == 'avi')
            return 'movie'
        else if (filetype == 'mp4')
            return 'mdi-video'
        else
            return 'mdi-file'
    }

    getColor(filetype) {
        if (filetype == 'pdf')
            return 'error'
        else if (filetype == 'xls' || filetype == 'xlsx')
            return 'green darken-2'
        else if (filetype == 'doc' || filetype == 'docx')
            return 'light-blue darken-4'
        else if (filetype == 'png' || filetype == 'jpg' || filetype == 'jpeg')
            return 'teal'
        else if (filetype == 'avi')
            return 'blue darken-1'
        else if (filetype == 'mp4')
            return 'indigo'
        else
            return 'primary'
    }
    
    onFileChange() {
        let ipt = (this.$refs.fileInput as any);
        if (ipt.files == undefined) return;
        if (ipt.files.length > 0) {
            if (!ipt.files.length)
                return;
            let file = ipt.files[0];

            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                let fileData = (e as any).target.result;
                vm.document.fileData = fileData;
                vm.document.name = file.name;
                vm.document.filename = file.name;
                vm.document.filetype = file.type;
                //vm.uploadBehavior();
            };
            reader.readAsDataURL(file);
        }
    }

    openFile() {
        (this.$refs.fileInput as any).click();
    }

    isVoteCount() {
        if (this.application.user_votes == null) return false;
        return this.application.user_votes.length > 0;
    }

    isVoted() {
        if (this.application.user_votes == null) return false;
        if (this.application.user_votes.length == 0) return false;
        return this.application.user_votes.indexOf(Util.abp.session.userId) >= 0;
    }

    /************* METODOS DE CALCULO ***************/
    showButton(btn) {
        if (btn == 'verify' && this.application.state_id == this.pState.NU && this.isRisk && this.tab == 'request')
            return true;
        if (btn == 'sentinel' && this.application.valid && this.isRisk && this.tab == 'request')
            return true;   
        if (btn == 'agreed' && this.application.valid && (this.application.state_id == this.pState.VE || this.application.state_id == this.pState.OB) && !this.application.request_review && !this.application.reviewed && this.isRisk && this.tab == 'statements')
            return true;
        if (btn == 'observe' && this.application.valid && !this.application.observed &&
            ((this.application.state_id == this.pState.VE && (this.isRisk || this.isRiskManager || this.isLeader) && this.tab == 'statements') ||
            (this.application.request_review && !this.application.reviewed && (this.isRisk || this.isRiskManager || this.isLeader) && this.tab == 'report')))
            return true;
        if (btn == 'requestReview' && this.application.valid && this.application.statement_aggreed && this.isRisk && this.tab == 'report' && (!this.application.request_review || (this.application.request_review && this.application.observed)))
            return true;
        if (btn == 'review' && this.application.valid && this.application.statement_aggreed && this.application.request_review && !this.application.observed && !this.application.reviewed && this.isRiskManager && this.tab == 'report')
            return true;
        if (btn == 'approve' && this.application.valid && this.application.reviewed && !this.application.observed && ((this.isAutonomyComite && this.isComite && !this.isVoted()) || (!this.isAutonomyComite && (this.isRisk || this.isRiskManager) && !this.isVoteCount())) && this.tab == 'request')
            return true;
        if (btn == 'vote' && (this.application.approved > 0 || this.application.rejected > 0) && (this.isRiskManager || this.isCommercial || this.isLeader || this.isRisk || this.isComite || Util.abp.session.isAdmin) && this.isAutonomyComite)
            return true;
        if (btn == 'comment' && this.application.valid && (this.isRiskManager || this.isRisk || this.isComite || Util.abp.session.isAdmin))
            return true;
        if (btn == 'close' && this.application.observed && !this.application.finished && this.expiredDays && this.isRisk)
            return true;
        if (btn == 'reopen' && (this.isRisk || this.isRiskManager) && (this.application.state_id == this.pState.RE || this.application.state_id == this.pState.AP || this.application.state_id == this.pState.DE))
            return true;
        return false;            
    }


    /************* BOTONES DE ACCION ***************/
    showHistory() {
        this.hisModalShow = !this.hisModalShow;
    }
                
    async verify() {
        this.$swal({
            title: 'Marcar como verificada la Solicitud?',
            text: 'Verifico correctamente el checklist de la solicitud y todos los datos',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Verificar!',
            cancelButtonText: 'No!',
            confirmButtonColor: '#4CAF50',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: this.entity + '/verify',
                    data: {id : this.application.id}
                });

                this.$emit('save-success');
                this.application.state_id = model.state_id;
                this.application.observed = model.observed;
                this.application.valid = model.valid;
                this.application.statement_aggreed = model.statement_aggreed;
                this.$swal('Verificado', 'La Solicitud de Evaluacion ha sido verificada!', 'success')
                
            }
        });
    }
            
    async agreed() {
        this.$swal({
            title: 'Esta todo conforme?',
            text: 'Desea proceder con la generacion del informe de riesgos potencial',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Conforme!',
            cancelButtonText: 'No!',
            confirmButtonColor: '#4CAF50',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: this.entity + '/agreed',
                    data: { id: this.application.id }
                });

                this.$emit('save-success');
                this.application.state_id = model.state_id;
                this.application.observed = model.observed;
                this.application.valid = model.valid;
                this.application.statement_aggreed = model.statement_aggreed;
                this.$swal('Conforme', 'La Solicitud ha sido confirmada!', 'success');

            }
        });
    }
    
    async observe() {
        this.$swal({
            title: 'Esta seguro de continuar?',
            text: 'Se le habilitara una ventana de observaciones',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Continuar!',
            cancelButtonText: 'No!',
            confirmButtonColor: '#F44336',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: this.entity + '/observe',
                    data: { id: this.application.id }
                });

                this.$emit('save-success');
                this.application.state_id = model.state_id;
                this.application.observed = model.observed;
                this.application.valid = model.valid;
                this.application.statement_aggreed = model.statement_aggreed;                   

            }
        });
    }

    async requestBehavior() {
        let model = await this.$store.dispatch({
            type: this.entity + '/requestBehavior',
            data: {
                id: this.application.id,
                existing: this.application.existing
            }
        });

        this.application.request_behavior = model.request_behavior;
        this.$swal('Solicitado', 'La Solicitud de Comportamiento de pago enviada!', 'success')
    }

    async uploadBehavior() {
        if (this.document.fileData != null) {
            let typeDo = Util.abp.list.get('T_DOCUMENT', 'TD13');
            this.document.application_id = this.application.id;
            this.document.document_type_id = typeDo.id;
            this.document['is_Behavior'] = true;
            await this.$store.dispatch({
                type: 'applicationdocument/upload',
                data: this.document
            });
            this.application.attached_behavior = true;

            this.document = Util.extend(true, {}, this.$store.state.applicationdocument.editModel);
            (this.$refs.fileInput as any).value = null;
            this.$swal('Cargado', 'El detalle de Comportamiento de pago ha sido actualizado!', 'success');


            if (this.$refs.docs != undefined)
                (this.$refs.docs as any).getpage();
        }
    }

    async requestReview() {
        let model = await this.$store.dispatch({
            type: this.entity + '/requestReview',
            data: {
                id: this.application.id
            }
        });

        this.application.observed = model.observed;
        this.application.request_review = model.request_review;
        this.application.valid = model.valid;
        this.application.statement_aggreed = model.statement_aggreed;
        this.$swal('Solicitud Enviada', 'La Solicitud de Revision de informe potencial ha sido enviada!', 'success')
    }

    async review() {
        let text = 'Desea proceder y enviar la solicitud de aprobacion ' + (this.application.autonomy_process == 'C' ? ' al comite' : '');

        this.$swal({
            title: 'Informe de Riesgo Revisado?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Revisar y Enviar!',
            cancelButtonText: 'No!',
            confirmButtonColor: '#4CAF50',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: this.entity + '/review',
                    data: { id: this.application.id }
                });

                this.$emit('save-success');
                this.application.state_id = model.state_id;
                this.application.observed = model.observed;
                this.application.reviewed = model.reviewed;
                this.application.valid = model.valid;
                this.application.statement_aggreed = model.statement_aggreed;
                this.$swal('Revisada', 'El informe de Riesgos ha sido revisado' + (this.application.autonomy_process == 'C' ? ' y hemos enviado la solicitud de aprobacion de comite!' : '!'), 'success');

            }
        });
    }

    async approve(response, comment) {
        let model = await this.$store.dispatch({
            type: this.entity + '/approve',
            data: {
                id: this.application.id,
                response: response,
                comment: comment,
            }
        });

        this.$emit('save-success');
        this.menu1 = false;
        this.menu2 = false;
        this.menu3 = false;
        this.comment = '';
        this.application.state_id = model.state_id;
        this.application.observed = model.observed;
        this.application.approved = model.approved;
        this.application.rejected = model.rejected;
        this.application.valid = model.valid;
        this.application.statement_aggreed = model.statement_aggreed;

        let title = (this.application.autonomy_process == 'C' ? 'Voto Emitido' : 'Respuesta Enviada');
        let text = (this.application.autonomy_process == 'C' ? 'Su Voto ha sido emitido, muchas gracias!' : 'Su Respuesta ha sido enviada, muchas gracias!');

        this.$swal(title, text, 'success');
        this.$emit('input', false)
    }

    async close() {
        let model = await this.$store.dispatch({
            type: this.entity + '/close',
            data: {
                id: this.application.id
            }
        });

        this.$emit('save-success');
        this.$emit('input', false)
    }

    async reopen() {
        let model = await this.$store.dispatch({
            type: this.entity + '/reopen',
            data: {
                id: this.application.id,
                update_notes: this.application.update_notes,
            }
        });

        this.$emit('save-success');
        this.$emit('input', false)
    }

    afterChangeVotes(model) {
        this.application.state_id = model.state_id;
        this.application.approved = model.approved;
        this.application.rejected = model.rejected;
        this.application.votes = model.votes;
        this.$store.commit('application/setVotes', model.votes);
    }

    sentinel() {
        this.$emit('on-sentinel', this.application);
    }

    cancel() {
        this.$emit('input', false);
    }

    async loadRisk() {
        let model = await this.$store.dispatch({
            type: 'riskreport/getRisk',
            data: {
                customer: this.application.customer_id,
                vertical: this.application.vertical_id,
                application: this.application.id
            }
        });
        this.$store.commit('riskreport/edit', model);
        if (this.$refs.risk != undefined) {
            (this.$refs.risk as any).found = true;
            (this.$refs.risk as any).pagerequest.customer = this.application.customer_id;
            (this.$refs.risk as any).pagerequest.vertical = this.application.vertical_id;                
            (this.$refs.risk as any).pagerequest.application = this.application.id;                
            (this.$refs.risk as any).riskreport = Util.extend(true, {}, this.$store.state.riskreport.editModel);
        }
    }

    async changeView(value) {
        if (value == 'report') {
            await this.loadRisk();
        }
    }

    async mounted() {

    }

    async created() {
        this.$store.dispatch({ type: 'manufacturer/getAllActive' });
    }
}
