





























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import VWidget from '@/components/VWidget.vue';
import ApplicationComment from '@/store/entities/financial/applicationcomment';
import Util from '@/lib/util'
import ListBase from '@/lib/listbase';

@Component({
    components: { ModalView, VWidget },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    },
})

export default class ApplicationCommentsList extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    entity: string = "applicationcomment";
    comment: ApplicationComment = new ApplicationComment();
    events: Array<any> = [];
    nonce: number = 0;
    modalSetting: any = {
        entity: "applicationcomment",
        titleFull: 'Observaciones',
        icon: 'comment',
        showForm: false,
        persistent: false,
        showFootActions: false,
        width: '600px'
    };

    onFileChange() {
        let ipt = (this.$refs.fileInput as any);
        if (ipt.files == undefined) return;
        if (ipt.files.length > 0) {
            if (!ipt.files.length)
                return;
            let file = ipt.files[0];

            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                let fileData = (e as any).target.result;
                vm.comment.fileData = fileData;
                vm.comment.filename = file.name;
                vm.comment.filetype = file.type;
            };
            reader.readAsDataURL(file);
        }
    }

    openFile() {
        (this.$refs.fileInput as any).click();
    }

    clearFile() {
        this.comment.filename = null;
        this.comment.fileData = null;
    }

    async save() {
        if (!this.disabled) {

            this.comment.application_id = this.application.id;

            await this.$store.dispatch({
                type: 'applicationcomment/create',
                data: this.comment
            });
            this.comment.comment = null;
            this.comment.filename = null;
            this.comment.fileData = null;

            await this.getpage();
        }
    }

    /************* COMPUTED LIST ***************/
    get userName() {
        return this.$store.state.session.user ? this.$store.state.session.user.full_name : ''
    }

    get loading() {
        return this.$store.state.applicationcomment.loading;
    }

    get list() {
        return this.$store.state.applicationcomment.list;
    }

    get totalCount() {
        return this.$store.state.applicationcomment.totalCount;
    }

    get application() {
        return this.$store.state.application.editModel;
    }

    get isComite() {
        return Util.abp.session.isComite();
    }

    get isRiskManager() {
        return Util.abp.session.isRiskManager();
    }

    get isRisk() {
        return Util.abp.session.isRisk();
    }

    get disabled() {
        return !(this.isComite || this.isRiskManager || this.isRisk);
    }

    /********* EVENTOS DE RETORNO DE MODALES ********/
    getIcon(filetype) {
        if (filetype == 'pdf')
            return 'mdi-file-pdf-box'
        else if (filetype == 'xls' || filetype == 'xlsx')
            return 'mdi-microsoft-excel'
        else if (filetype == 'doc' || filetype == 'docx')
            return 'mdi-microsoft-word'
        else if (filetype == 'png' || filetype == 'jpg' || filetype == 'jpeg')
            return 'mdi-image'
        else if (filetype == 'avi')
            return 'movie'
        else if (filetype == 'mp4')
            return 'mdi-video'
        else
            return 'mdi-file'
    }

    getColor(filetype) {
        if (filetype == 'pdf')
            return 'error'
        else if (filetype == 'xls' || filetype == 'xlsx')
            return 'green darken-2'
        else if (filetype == 'doc' || filetype == 'docx')
            return 'light-blue darken-4'
        else if (filetype == 'png' || filetype == 'jpg' || filetype == 'jpeg')
            return 'teal'
        else if (filetype == 'avi')
            return 'blue darken-1'
        else if (filetype == 'mp4')
            return 'indigo'
        else
            return 'primary'
    }

    async initForm() {
        this.comment.comment = null;
        await this.getpage();
    }

    async getpage() {
        this.$store.commit('applicationcomment/new');
        this.comment = Util.extend(true, {}, this.$store.state.applicationcomment.editModel);
        await this.$store.dispatch({
            type: 'applicationcomment/getAll',
            data: { id: this.application.id }
        });
    }

    cancel() {
        this.$emit('input', false);
    }

    async mounted() {
    }
}
