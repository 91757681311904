








import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Customer from '@/store/entities/catalogs/customer';

@Component({
    components: { FormBase, ModalView },
    data() {
        return {
            modalShow: false
        }
    },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                //obj.initForm();
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            } else {
                (this as any).customer = Util.extend(true, {}, this.$store.state.customer.editModel);
                (this as any).initForm();
            }
        }
    }
})

export default class CustomerForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    customer: Customer = new Customer();
    showApprove: boolean = false;
    modalSetting: any = {
        entity: "customer",
        title: 'Cliente',
        width: "800px"
    };

    get loading() {
        return this.$store.state.customer.loading;
    }

    personType(id) { //evento para cambio de tipo de persona
        let data = Util.getTypeValueById(id);
        if (data == undefined) return;
        let form = (this as any).$refs.form;
        let documentType = Util.getEntityByParam(this, 'documenttype', 'code', (data.code == 'J' ? 'RUC' : 'DNI'));
        if (documentType != undefined) {
            this.customer.document_type_id = documentType.id;
            form.model.document_type_id = documentType.id;
        }

        this.setItem(this.items, 'company', 'visible', (data.code == 'J'));
        this.setItem(this.items, 'firstname', 'visible', (data.code == 'N'));
        this.setItem(this.items, 'lastname', 'visible', (data.code == 'N'));
        this.setItem(this.items, 'company', 'required', (data.code == 'J'));
        this.setItem(this.items, 'firstname', 'required', (data.code == 'N'));
        this.setItem(this.items, 'lastname', 'required', (data.code == 'N'));
    }

    async getLastCode() { //evento para consultar el ultimo codigo disponible para clientes
        let form = (this as any).$refs.form.$refs.entityForm;
        let code = await this.$store.dispatch({
            type: 'customer/getLastCode'
        });
        if (form != undefined)
            form.model.code = code;

        this.customer.code = code;
    }

    items = [
        this.gItem({ type: 'radio', prop: 'person_type_id', label: 'Tipo de Persona', generalType: 'T_PERSON', required: true, size: 8, row: true, onchange: this.personType, flexClass: 'pb-4' }),
        this.gItem({ type: 'spacer', size: 2 }),
        this.gItem({ type: 'active', size: 2, flexClass: 'pb-4' }),
        this.gItem({ type: 'object', prop: 'document_type_id', label: 'Tipo de Documento', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'document', label: 'Documento', size: 6, required: true }),
        this.gItem({ type: 'text', prop: 'company', label: 'Razon Social', required: true, visible: false, size: 12 }),
        this.gItem({ type: 'text', prop: 'firstname', label: 'Nombres', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'lastname', label: 'Apellidos', required: true, size: 6 }),
        this.gItem({ type: 'phone', prop: 'phone', label: 'Celular', size: 6 }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', size: 6 }),
    ]

    initForm() {
        let $this = (this as any);
        let model = this.$store.state.customer.editModel;
        this.setItem(this.items, 'active', 'disabled', false);
        if (model.id > 0) {
            this.personType(model.person_type_id);
            this.setItem(this.items, 'code', 'disabled', true);

        } else {
            this.setItem(this.items, 'code', 'disabled', false);
            setTimeout(() => {
                $this.$refs.form.$refs.entityForm.$refs.document[0].focus();
                $this.getLastCode();
            }, 100);
        }

        if (this.customer.person_type_id > 0)
            this.personType(this.customer.person_type_id);

    }

    async save(item) {
        this.$emit('save-success', item);
        this.$emit('input', false);
    }


    cancel() {
        this.$emit('input', false);
    }

    async mounted() {
    }

    async created() {
    }
}
