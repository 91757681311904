













































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import ListMenu from '@/components/list-menu/list-menu.vue';
import Util from '@/lib/util'
import ListBase from '@/lib/listbase';
import Application from '@/store/entities/financial/application';
import History from '@/views/_shared/history.vue'; 
import Proposals from './proposals.vue';
import Documents from './documents.vue';


@Component({
    components: {
        FormBase, ModalView, VWidget, ListMenu, History, Proposals, Documents
    },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    },
})

export default class ApplicationForm extends ListBase {
    entity: string = 'application';
    @Prop({ type: Boolean, default: false }) value: boolean;
    application: Application = new Application();
    hisModalShow: boolean = false;
    tabModel: any = null;
    pState: any = Util.abp.list.getCodeList('E_APPLY');
    tVert: any = Util.abp.list.getCodeList('T_VERTICAL');
    modalSetting: any = {
        entity: "application",
        titleFull: 'Nueva Solicitud de Evaluacion',
        showForm: false,
        fullscreen: true,
        disabledSave: false,
        showFloatFootActions: true,
    };

    /************* ARRAY ATTRIBUTES ***************/
    itemsInfo = [
        this.gItem({ type: 'radio', prop: 'autonomy_process', label: 'Proceso de Autonomia', size: 12, options: 'autonomys', row: true }),
        this.gItem({ type: 'date', prop: 'issue_date', label: 'Fecha de Solicitud', size: 6 }),
        //this.gItem({ type: 'object', prop: 'customer_id', label: 'Cliente', size: 6, exclShortkey: true, required: true, autocomplete: true, itemText: 'description', outerIcon: 'add_circle', appendEvent: this.new_customer }),
        //this.gItem({ type: 'object', prop: 'representative_id', label: 'Ejecutivo Responsable', entity: 'customer', required: true, size: 6, exclShortkey: true, autocomplete: true, itemText: 'description', outerIcon: 'add_circle', appendEvent: this.new_customer }),
        //this.gItem({ type: 'object', prop: 'guarantee_id', label: 'Aval', entity: 'customer', size: 6, hideDetails: true, visible: false, exclShortkey: true, autocomplete: true, itemText: 'description', outerIcon: 'add_circle', appendEvent: this.new_customer }),
        this.gItem({ type: 'text', prop: 'customer_name', label: 'Cliente', size: 6, readonly:true }),
        this.gItem({ type: 'text', prop: 'representative_name', label: 'Representante Legal', size: 6, readonly: true }),
        this.gItem({ type: 'text', prop: 'guarantee_name', label: 'Aval', size: 6, readonly: true }),
        this.gItem({ type: 'object', prop: 'currency_id', label: 'Moneda', entity: 'currency', size: 6, hideDetails: true, required: true, hint: '', persistentHint: true, onchange: this.changeCurrency }),
        this.gItem({ type: 'text', prop: 'emsa_company', label: 'Empresa EMSA', size: 6, hideDetails: true }),            
    ];
     

    itemsF = [
        this.gItem({ type: 'object', prop: 'customer_type_id', label: 'Tipo de Cliente', generalType: 'T_CUSTOMER', size: 4 }),
        this.gItem({ type: 'object', prop: 'annual_sale_id', label: 'Ventas Anuales', generalType: 'ANNUAL_SALE', size: 4 }),
        this.gItem({ type: 'object', prop: 'segment_id', label: 'Segmento', generalType: 'SEGMENT', size: 4 }),
        this.gItem({ type: 'price', prop: 'total_income', label: 'Renta Total', hint: 'Sin IGV', persistentHint: true, size: 4, prefix: this.application.currency_sign }),
        this.gItem({ type: 'price', prop: 'total', label: 'Total Inversion', hint: 'Sin IGV', persistentHint: true, size: 4, prefix: this.application.currency_sign }),
        this.gItem({ type: 'text', prop: 'autonomy', label: 'Autonomia', size: 4 }),
        this.gItem({ type: 'switch', prop: 'special_perm', label: 'Permisos Especiales', size: 4 }),
        this.gItem({ type: 'text', prop: 'specification', label: 'Especificar', size: 8, maxLen: 255 }),
        this.gItem({ type: 'textarea', prop: 'description', label: 'Descripcion de Empresa', size: 12, maxLen: 1000 }),
        this.gItem({ type: 'textarea', prop: 'warranty', label: 'Condiciones y/o Garantias', size: 12, maxLen: 1000 }),
        this.gItem({ type: 'object', prop: 'evaluator_id', label: 'Evaluador', entity: 'user', itemText: 'full_name', size: 6 }),
        this.gItem({ type: 'spacer', prop: 'spacer', size: 6 }),
    ];

    itemsT = [
        //this.gItem({ type: 'text', prop: 'good', label: 'Bien a Financiar', size: 12 }),
        //this.gItem({ type: 'object', prop: 'manufacturer_id', label: 'Marca', entity: 'manufacturer', size: 6 }),
        //this.gItem({ type: 'price', prop: 'total', label: 'Monto De Mutuo', size: 3, prefix: this.application.currency_sign }),
        //this.gItem({ type: 'price', prop: 'prepaid', label: 'Aporte del Cliente', size: 3, prefix: this.application.currency_sign }),
        //this.gItem({ type: 'number', prop: 'months', label: 'Plazo', size: 3, suffix: 'Meses' }),
        //this.gItem({ type: 'percent', prop: 'tea', label: 'TEA', size: 3 }),
        //this.gItem({ type: 'number', prop: 'days_stock', label: 'Dias en Stock', size: 3, suffix: 'Dias' }),
        //this.gItem({ type: 'percent', prop: 'profit', label: 'Margen', size: 3 }),
        this.gItem({ type: 'textarea', prop: 'warranty', label: 'Garantia', size: 9, maxLen: 1000 }),
        this.gItem({ type: 'number', prop: 'assets', label: 'Activos de la Empresa', size: 3, prefix: '#' }),
        this.gItem({ type: 'textarea', prop: 'description', label: 'Descripcion de Empresa', size: 12, maxLen: 1000 }),
        this.gItem({ type: 'textarea', prop: 'notes', label: 'Proposito', size: 12, maxLen: 1000 }),
    ];

    get columnsT() {
        let sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
        if (this.application != null) {
            let data = Util.getEntity(this, 'currency', this.application.currency_id);
            if (data != undefined) {
                sign = data.sign;
            }
        }

        return [
            { value: 'number', text: '#', sortable: false },
            { value: 'good', text: 'Bien a Financiar', sortable: false, class: 'px-1' },
            { value: 'quantity', text: 'Cantidad', sortable: false, class: 'px-1' },
            { value: 'manufacturer', text: 'Marca', sortable: false, class: 'px-1' },
            { value: 'total_amount', text: 'Monto Total', sortable: false, class: 'px-1' },
            { value: 'total', text: 'Monto Mutuo (' + sign + ')', sortable: false, class: 'px-1' },
            { value: 'prepaid', text: 'Aporte Cliente (' + sign + ')', sortable: false, class: 'px-1' },
            { value: 'months', text: 'Plazo (Meses)', sortable: false, class: 'px-1' },
            { value: 'tea', text: 'TEA (%)', sortable: false, class: 'px-1' },
            { value: 'days_stock', text: 'Dias Stock', sortable: false, class: 'px-1' },
            { value: 'profit', text: 'Margen (%)', sortable: false, class: 'px-1' }
        ];
    }

    /************* COMPUTED METODOS ***************/
    get loading() {
        return this.$store.state[this.entity].loading;
    }

    get showActions() {
        let value = (this.showButton('verify') || this.showButton('sentinel'));
        return value;
    }
    
    get verticalName() {
        if (this.application != undefined && this.application.vertical_id != null) {
            return Util.abp.list.getItem(this.application.vertical_id).name;
        } else 
            return '';
    }

    get current_state() {
        if (this.application == undefined) return {};
        return Util.abp.list.getItem(this.application.state_id);
    }

    get currency_sign() {
        return Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');            
    }
    
    get has_changes() {
        return this.$store.state.application.has_changes;
    }

    get isRisk() {
        return Util.abp.session.isRisk();
    }
    
    get goods() {
        return this.$store.state.application.goods;
    }

    /************* METODOS DE INICIALIZACION ***************/
    changeVertical(id) {
        let data = Util.getTypeValueById(id);
        if (data == undefined) return;

        this.setItem(this.itemsInfo, 'representative_name', 'visible', (data.code == 'T'));
        this.setItem(this.itemsInfo, 'guarantee_name', 'visible', (data.code == 'T'));
        this.setItem(this.itemsInfo, 'guarantee_id', 'visible', (data.code == 'T'));
    }

    changeCurrency(id) {
        let data = Util.getEntity(this, 'currency', id);
        if (data == undefined) return;
        this.application.currency_sign = data.sign;
        this.application.exchange_rate = data.conversion_rate;
        this.setItem(this.itemsInfo, 'currency_id', 'hint', (data.conversion_rate != 1 ? 'TC: ' + data.conversion_rate : ''));
        
        if (this.application.vertical_id == this.tVert.T) {
            this.setItem(this.itemsT, 'total', 'prefix', data.sign);
            this.setItem(this.itemsT, 'prepaid', 'prefix', data.sign);
        } else if (this.application.vertical_id == this.tVert.F) {
            this.setItem(this.itemsF, 'total', 'prefix', data.sign);
            this.setItem(this.itemsF, 'total_income', 'prefix', data.sign);
        }
    }

    addGood(item) {
        if (item.good != null && item.good != '') {
            this.$store.commit('application/addGood');
        }
    }

    deleteGood(index) {
        this.$store.commit('application/deleteGood', index);
    }

    initForm() {
        this.application = Util.extend(true, {}, this.$store.state.application.editModel);
        this.$store.commit('applicationproposal/init', this.application);
        let form = (this as any).$refs.form;
        form.mainSetting.showFloatFootActions = this.showActions;
        form.mainSetting.disabledSave = false;
        form.mainSetting.titleFull = 'Solicitud de Evaluacion #' + this.application.code;
        
        this.changeVertical(this.application.vertical_id);
        this.changeCurrency(this.application.currency_id);
        
        if (this.$refs.docs != undefined)
            (this.$refs.docs as any).getpage();
    }

    /************* METODOS DE CALCULO ***************/
    changeTab(idx) {

    }

    buildModel() {
        let model = Util.extend(true, {}, this.application);
        model['proposals'] = [];
        delete model['customer'];
        delete model['currency'];
        delete model['representative'];
        this.$store.state.applicationproposal.list.forEach(item => {
            let det = Util.extend(true, {}, item);
            delete det['operation'];
            model['proposals'].push(det);
        });

        return model;
    }

    showButton(btn) {
        if (btn == 'verify' && this.application.state_id == this.pState.NU && this.isRisk)
            return true;
        if (btn == 'sentinel' && this.application.valid && this.isRisk)
            return true;     
        return false;            
    }
    /************* BOTONES DE ACCION ***************/
    showHistory() {
        this.hisModalShow = !this.hisModalShow;
    }

    async save() {            
        if ((this.$refs.infoForm as any).validate()) {
            let model = this.buildModel();

            model.goods = this.goods;

            let newModel = await this.$store.dispatch({
                type: this.entity + '/update',
                data: model
            });

            this.$emit('save-success');

            if (close) {
                Util.abp.notify.success(this, 'Solicitud de Evaluacion Actualizada correctamente!');
                this.cancel();
            } else {
            }
            return true;
        }
        return false;
    }
         

    async verify() {
        this.$swal({
            title: 'Marcar como verificada la Solicitud?',
            text: 'Verifico correctamente el checklist de la solicitud y todos los datos',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Verificar!',
            cancelButtonText: 'No!',
            confirmButtonColor: '#4CAF50',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true
        }).then(async (result) => {
            if (result.value) {
                if (this.has_changes) {
                    let res = await this.save();
                    if (!res) return;
                }

                let model = await this.$store.dispatch({
                    type: this.entity + '/verify',
                    data: {id : this.application.id}
                });

                this.$emit('save-success');
                this.application.state_id = model.state_id;
                this.$swal('Verificado', 'La Solicitud de Evaluacion ha sido verificada!', 'success')
                
            }
        });
    }

    sentinel() {
        this.$emit('on-sentinel', this.application);
    }

    async cancelOrder() {
        this.$swal({
            title: 'Anular Solicitud de Evaluacion?',
            text: 'Al anular la Solicitud de Evaluacion quedara inabilitada del sistema',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Anular!',
            cancelButtonText: 'No!',
            confirmButtonColor: '#FF5252',
            showCloseButton: true
        }).then(async (result) => {
            if (result.value) {
                await this.$store.dispatch({
                    type: this.entity + '/delete',
                    data: this.application
                });

                this.$emit('save-success');
                this.$emit('input', false);
                this.$swal('Anulado', 'La Solicitud de Evaluacion ha sido anulada!', 'success')
            }
        });
    }

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }


    async mounted() {

    }

    async created() {
        this.$store.dispatch({ type: 'manufacturer/getAllActive' });
    }
}
