































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util'
import ListBase from '@/lib/listbase';

@Component({
    components: { ModalView, VWidget },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    },
})

export default class ApplicationForm extends ListBase {
    name: string = 'create-application';
    entity: string = 'application';
    @Prop({ type: Boolean, default: false }) value: boolean;
    menu: boolean = false;
    comment: any = '';
    modalSetting: any = {
        entity: "application",
        titleFull: 'Votos Emitidos',
        showForm: false,
        persistent: false,
        showFootActions: false,
        width: '600px'
    };
    
    /************* ARRAY ATTRIBUTES ***************/
    


    /************* COMPUTED METODOS ***************/
    get loading() {
        return this.$store.state[this.entity].loading;
    }

    get userId() {
        return Util.abp.session.userId;
    }

    get votes() {
        return this.$store.state.application.editModel.votes;
    }

    get application() {
        return this.$store.state.application.editModel;
    }

    /************* METODOS DE INICIALIZACION ***************/
    initForm() {
        //this.application = Util.extend(true, {}, this.$store.state.application.editModel);
  
    }

    /************* METODOS DE CALCULO ***************/


    /************* BOTONES DE ACCION ***************/
    async response(response, comment) {
        let model = await this.$store.dispatch({
            type: 'application/approve',
            data: {
                id: this.application.id,
                response: response,
                comment: comment,
            }
        });

        this.$emit('save-success', model);
        this.menu = false;
        this.comment = '';

        let title = (this.application.autonomy_process == 'C' ? 'Voto Emitido' : 'Respuesta Enviada');
        let text = (this.application.autonomy_process == 'C' ? 'Su Voto ha sido emitido, muchas gracias!' : 'Su Respuesta ha sido enviada, muchas gracias!');

        this.$swal(title, text, 'success');
        this.$emit('input', false);
    }

    cancel() {
        this.$emit('input', false);
    }

    mounted() {

    }

    created() {
    }
}
