








































import { Component, Vue, Prop } from 'vue-property-decorator';
import AbpBase from '../lib/abpbase';
import Util from '@/lib/util';

@Component({
    components: {},
})

export default class Chat extends AbpBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ type: Number, default: null }) selected: number;
    @Prop({ type: String, default: 'application' }) entity: string;
    titleImageUrl: string = 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png';
    isChatOpen: boolean = false;
    colors: any = {
        header: {
            bg: '#4e8cff',
            text: '#ffffff'
        },
        launcher: {
            bg: '#4e8cff'
        },
        messageList: {
            bg: '#ffffff'
        },
        sentMessage: {
            bg: '#4e8cff',
            text: '#ffffff'
        },
        receivedMessage: {
            bg: '#eaeaea',
            text: '#222222'
        },
        userInput: {
            bg: '#f4f7f9',
            text: '#565867'
        }
    };
    alwaysScrollToBottom: boolean = false;
    messageStyling: boolean = true;

    sendMessage(text) {
        if (text.length > 0) {
            //this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1;
            this.onMessageWasSent({ author: 'support', type: 'text', data: { text } });
        }
    }
    onMessageWasSent(message) {
        // called when the user sends a message
        //this.messageList = [...this.messageList, message];
        //console.log(this.messageList);

        let filter_id = 1;
        if (this.operation.observed && this.operation.request_review)
            filter_id = 2;
        else if (this.operation.observed && this.operation.reviewed)
            filter_id = 3;

        let data: any = {
            filter_id: filter_id,
            type: message.type,
            author: message.author,
            text: message.data.text,
            code: message.data.emoji,
        }

        if (this.conversation != null && this.conversation.id > 0) {
            data.id = this.conversation.id;
            data.to_user_id  = (Util.abp.session.userId == this.conversation.user_to_id ? this.conversation.user_from_id : this.conversation.user_to_id);
        } else {
            data.to_user_id = this.operation.user_id;
            if (filter_id == 2)
                data.to_user_id = this.operation.user_risk_id;
            else if (filter_id == 3)
                data.to_user_id = 0;
        }

        if (message.data.file != undefined) {
            data.filename = message.data.file.name;
            let reader = new FileReader();
            let vm = this;
            let data2 = data;
            reader.onload = (e) => {
                data2.filedata = (e as any).target.result;

                vm.$store.dispatch({
                    type: 'conversation/' + (vm.conversation != null && vm.conversation.id > 0 ? 'update' : 'create'),
                    data: data2
                });
            };
            reader.readAsDataURL(message.data.file);

        } else {
            this.$store.dispatch({
                type: 'conversation/' + (this.conversation != null && this.conversation.id > 0 ? 'update' : 'create'),
                data: data
            });
        }
    }
    openChat() {
        // called when the user clicks on the fab button to open the chat
        this.isChatOpen = true;
        //this.newMessagesCount = 0
        this.$store.commit('conversation/resetMessagesCount');
    }
    closeChat() {
        // called when the user clicks on the botton to close the chat
        this.isChatOpen = false
    }
    handleScrollToTop() {
        // called when the user scrolls message list to top
        // leverage pagination for loading another page of messages
    }
    handleOnType() {
        if (this.conversation != null && this.conversation.id > 0) {
            //console.log('Emit typing event')
            //this.$store.dispatch({
            //    type: 'conversation/updateTyping',
            //    data: {
            //        id: this.conversation.id,
            //        to_user_id: (Util.abp.session.userId == this.conversation.user_id ? this.conversation.user_risk_id : this.conversation.user_id),
            //        username: Util.abp.session.username,
            //    }
            //});
        }
    }
    editMessage(message) {
        const m = this.messageList.find(m => m.id === message.id);
        m.isEdited = true;
        m.data.text = message.data.text;
    }


    get title() {
        let text = 'Observaciones de EF';
        if (this.operation.observed && this.operation.request_review)
            text = 'Observaciones Informe de Riesgos';
        else if (this.operation.observed && this.operation.reviewed)
            text = 'Consultas';

        return text;
    }

    get subtitle() {
        return 'Solicitud de Evaluacion #' + this.operation.code;
    }

    get participants() {
        let list = [];
        if (this.conversation != null && this.conversation.id > 0) {
            list.push({
                id: this.conversation.user_to.username,
                name: this.conversation.user_to.full_name,
            });

            if(this.conversation.user_from != null){
                list.push({
                    id: this.conversation.user_from.username,
                    name: this.conversation.user_from.full_name
                });
            }
        }

        return list;
    }

    get messageList() {
        return this.$store.state.conversation.messages;
    }

    get showTypingIndicator() {
        return this.$store.state.conversation.showTypingIndicator;
    }

    get operation() {
        return this.$store.state[this.entity].editModel;
    }
    get app_state() {
        if (this.selected == null) return 'primary';
        return Util.abp.list.getItem(this.operation.state_id).extra;
    }

    get conversation() {
        //return this.$store.state.conversation.list.filter( item => {
        //    return item.filter_id == this.claim.id;
        //})[0];
        return this.$store.state.conversation.editModel;
    }

    get newMessagesCount() {
        return this.messageList.length;
    }

    getDealerName(id) {
        if (id == null) return '';
        let entity = Util.getEntity(this, 'dealer', id);
        if (entity == null) return '';
        return entity.fullname;
    }

    async created() {

    }
}
