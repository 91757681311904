

































































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import AbpBase from '@/lib/abpbase'
import VWidget from '@/components/VWidget.vue';


@Component({
    components: { VWidget }, 
})
export default class Proposals extends AbpBase {
    entity: string = 'application'
    highlightIndex: number = -1;
    selected: Array<any> = [];
    options: any = {};
    pState: any = Util.abp.list.getCodeList('E_APPLY');

    /************* COMPUTED LIST ***************/
    get columns(): Array<any> {
        let cols: Array<any> = [
            { value: 'operation_id', text: 'Tipo de Operacion', type: 'text', sortable: false },
            { value: 'quantity', text: 'Cantidad', type: 'int', sortable: false },
            { value: 'model', text: 'Marca/Modelo', type: 'text', sortable: false },
            { value: 'vehicle_type', text: 'Tipo Vehiculo', type: 'text', sortable: false },
            { value: 'months', text: 'Plazo', type: 'int', sortable: false },
            { value: 'day_fee', text: 'Tarifa Dia', type: 'float', sortable: false },
            { value: 'month_fee', text: 'Tarifa Mes', type: 'total', sortable: false },
            { value: 'price', text: 'Inv. Unid', type: 'float', sortable: false },
            { value: 'equipment', text: 'Equipamiento', type: 'float', sortable: false },
            { value: 'total', text: 'Inv. Total', type: 'total', sortable: false }
        ];

        return cols;
    }

    get list() {
        this.$store.state.applicationproposal.list.forEach(i => {
            i.operation = this.getItem(i.operation_id);
        });
        return this.$store.state.applicationproposal.list;
    }
    get loading() {
        return this.$store.state.applicationproposal.loading;
    }
    get totalCount() {
        return this.$store.state.applicationproposal.totalCount;
    }
    get application() {
        return this.$store.state.application.editModel;
    }
    get isEditable() {
        return !this.application.valid;
    }
    get operations() {
        return Util.abp.list.getList('T_OPERATION');
    }
    //get manufacturers() {
    //    let list = [];
    //    this.$store.state.manufacturer.activeList.forEach(ma => {
    //        let name = ma.name;
    //        if (ma.models != null && ma.models.length > 0) {
    //            ma.models.forEach(model => {
    //                list.push({
    //                    id: ma.id,
    //                    model_id: model.id,
    //                    name: name + ' ' + model.name,
    //                });
    //            });
    //        } else {
    //            list.push({
    //                id: ma.id,
    //                model_id: 0,
    //                name: name,
    //            });
    //        }
    //    });
    //    return list;
    //}
    


    /************* BOTONES DE ACCION ***************/
    getItem(id) {
        return Util.abp.list.getItem(id);
    }
    onFocus(e) {
        e.target.select();
    }
    createItem() {
        if (this.isEditable) {
            this.$store.commit('applicationproposal/add');
        }
    }
    deleteProduct(idx) {
        if (this.isEditable) {
            this.$store.commit('applicationproposal/delete', idx);
        }
    }
    onClickOutside() {
        this.highlightIndex = -1;
    }

    /********* EVENTOS DE RETORNO DE MODALES ********/


    /************* EVENTOS DE FILA ***************/
    propValue(data: any, field: string) {
        let value = Util.getEntityValue(data, field);
        return value;
    }
    updateDetail(item) {
        item.quantity = parseFloat(String(item.quantity));
        item.day_fee = parseFloat(String(item.day_fee));
        item.price = parseFloat(String(item.price));
        item.equipment = parseFloat(String(item.equipment));
        item.month_fee = (item.day_fee * 365) / 12;
        item.total = item.price + item.equipment; 
    }
    changeModel(item) {
        console.log(item);
    }
    onRowClick(row: any, index: any) {
        this.highlightIndex = index;
    }
    onSelecteItems(items) {

    }

    created() {

    }

    mounted() {

    }
}
