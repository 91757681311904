










































































































































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase'
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import ApplicationDocument from '@/store/entities/financial/applicationdocument';
import CreateDocument from './create_document.vue';
import url from '@/lib/url'

@Component({
    components: { VWidget, FormBase, CreateDocument },
})

export default class ApplicationDocumentsList extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    entity: string = "application";
    document: ApplicationDocument = new ApplicationDocument();
    docModalShow: boolean = false;
    modalShow: boolean = true;
    menu: boolean = false;
    openItem: any = null;
    checklist: Array<any> = new Array<any>();
    tVert: any = Util.abp.list.getCodeList('T_VERTICAL');


    getIcon(filetype) {
        if (filetype == 'pdf')
            return 'mdi-file-pdf-box'
        else if (filetype == 'xls' || filetype == 'xlsx')
            return 'mdi-microsoft-excel'
        else if (filetype == 'doc' || filetype == 'docx')
            return 'mdi-microsoft-word'
        else if (filetype == 'png' || filetype == 'jpg' || filetype == 'jpeg')
            return 'mdi-image'
        else if (filetype == 'avi')
            return 'movie'
        else if (filetype == 'mp4')
            return 'mdi-video'
        else
            return 'mdi-file'
    }

    getColor(filetype) {
        if (filetype == 'pdf')
            return 'error'
        else if (filetype == 'xls' || filetype == 'xlsx')
            return 'green darken-2'
        else if (filetype == 'doc' || filetype == 'docx')
            return 'light-blue darken-4'
        else if (filetype == 'png' || filetype == 'jpg' || filetype == 'jpeg')
            return 'teal'
        else if (filetype == 'avi')
            return 'blue darken-1'
        else if (filetype == 'mp4')
            return 'indigo'
        else
            return 'primary'
    }

    onFileChange() {
        let ipt = (this.$refs.fileInput as any);
        if (ipt.files == undefined) return;
        if (ipt.files.length > 0) {
            if (!ipt.files.length)
                return;
            let file = ipt.files[0];

            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                let fileData = (e as any).target.result;
                vm.document.fileData = fileData;
                vm.document.filename = file.name;                    
                vm.document.filetype = file.type;
                vm.save();
            };
            reader.readAsDataURL(file);
        }
    }

    openImage(item) {
        (this.$refs.fileInput as any).click();
        this.openItem = item;
    }
     
    async save() {
        if (this.document.fileData != null) {
            this.document.application_id = this.application.id;
            this.document.id = this.openItem.id;
            this.document.document_type_id = this.openItem.document_type_id;
            await this.$store.dispatch({
                type: 'applicationdocument/upload',
                data: this.document
            });
            this.document = Util.extend(true, {}, this.$store.state.applicationdocument.editModel);
            (this.$refs.fileInput as any).value = null;
            Util.abp.notify.success(this, 'Tu documento ha sido actualizado!');
            await this.getpage();
        }
    }

    async saveDescription(item) {
        if (item.description != null && item.description != '') {
            item.application_id = this.application.id;
            await this.$store.dispatch({
                type: 'applicationdocument/' + (item.id > 0 ? 'update' : 'create'),
                data: item
            });
            await this.getpage();
        }
    }

    async saveName(item) {
        if (item.name != null && item.name != '' && item.id > 0) {
            item.application_id = this.application.id;
            await this.$store.dispatch({
                type: 'applicationdocument/' + (item.id > 0 ? 'update' : 'create'),
                data: item
            });
            await this.getpage();
        }
    }

    async validateDoc(item, response) {
        if (this.isApprover) {
            await this.$store.dispatch({
                type: 'applicationdocument/validate',
                data: {
                    id: item.id,
                    response: response,
                    comment: item.comment,
                }
            });
            item.verified = response;
            await this.getpage();
        }
    }

    async deleteDocument(item) {
        if (item.id > 0) {
            await this.$store.dispatch({
                type: 'applicationdocument/delete',
                data: item
            });
            await this.getpage();
        }
    }

    creatDocument() {
        this.$store.commit('applicationdocument/new')
        this.docModalShow = !this.docModalShow;
    }


    /************* COMPUTED LIST ***************/
    get loading() {
        return this.$store.state.applicationdocument.loading || this.$store.state.applicationdocument.itemLoading;
    }

    get isApprover(): boolean {
        return Util.abp.session.isRisk();
    }

    get isCommercial() {
        return Util.abp.session.isCommercial();
    }

    get list() {
        this.checklist = [];
        this.documents.forEach(item => {
            this.checklist[item.id] = {
                document_type_id: item.id,
                verified: false,
                uploaded: false
            };
        });

        this.$store.state.applicationdocument.list.forEach(item => {
            if (item.document_type_id != null) {
                this.checklist[item.document_type_id] = item;
            } 
        });
        return this.checklist;
    }

    get aditionalList() {
        return this.$store.state.applicationdocument.list.filter(d => { return d.document_type_id == null });
    }

    get totalCount() {
        return this.$store.state.applicationdocument.totalCount;
    }

    get application() {
        return this.$store.state.application.editModel;
    }

    get vertical() {
        return Util.abp.list.getItem(this.$store.state.application.editModel.vertical_id);
    }

    get documents() {
        return Util.abp.list.getList('T_DOCUMENT').filter(d => { return d.extra == 'B' || d.extra == this.vertical.code });
    }  

    /********* EVENTOS DE RETORNO DE MODALES ********/
    async getpage() {
        await this.$store.dispatch({
            type: 'applicationdocument/getAll',
            data: { id: this.application.id}
        });
    }

    async mounted() {
        await this.getpage();
    }
}
